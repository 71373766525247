import { Differ } from 'json-diff-kit';

const differ = new Differ({
  detectCircular: true,
  maxDepth: Infinity,
  showModifications: true,
  arrayDiffMethod: 'lcs',
});

export const createDiff = (left, right) => {
  return differ.diff(left || {}, right || {});
};
