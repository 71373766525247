import React, { useState } from 'react';
import Toggle from 'react-toggle';
import { Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import { Viewer } from 'json-diff-kit';
import 'json-diff-kit/dist/viewer.css';
import { createDiff } from './functions';

const App = () => {
  const [left, setLeft] = useState(null);
  const [right, setRight] = useState(null);
  const [hideUnchangedLines, setHideUnchangedLines] = useState(false);

  let l;
  let r;

  try {
    l = JSON.parse(left);
  } catch (e) {
    l = left;
  }

  try {
    r = JSON.parse(right);
  } catch (e) {
    r = right;
  }

  const diff = createDiff(l, r);

  return (<div className="p-3">
    <div className="p-5 mb-4 bg-light rounded-3">
      <Row className="mb-3">
        <Col xs={6}>
          <InputGroup>
            <InputGroup.Text id="value-input">
              Left
            </InputGroup.Text>
            <FormControl
              placeholder="Enter left data..."
              aria-label="Enter left data..."
              aria-describedby="value-input"
              value={left}
              onChange={e => setLeft(e.target.value)} />
          </InputGroup>
        </Col>
        <Col xs={6}>
          <InputGroup>
            <InputGroup.Text id="value-input">
              Right
            </InputGroup.Text>
            <FormControl
              placeholder="Enter right data..."
              aria-label="Enter right data..."
              aria-describedby="value-input"
              value={right}
              onChange={e => setRight(e.target.value)} />
          </InputGroup>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col xs={12}>
          <label>
            <div style={{ display: 'flex', justifyContent: 'flex-start' }}>
              <div style={{ alignItems: 'center', margin: '0 20px 0 0' }}>
                <Toggle checked={hideUnchangedLines} onChange={(e) => setHideUnchangedLines(e.target.checked)} />
              </div>
              <div style={{ alignItems: 'center' }}>
                <span>Hide unchanged lines</span>
              </div>
            </div>
          </label>
        </Col>
      </Row>
      {(left && right) && <Row className="mb-5">
        <Col xs={12}>
          <Viewer
            diff={diff}
            indent={4}
            lineNumbers
            highlightInlineDiff
            hideUnchangedLines={hideUnchangedLines} />
        </Col>
      </Row>}
    </div>
  </div>);
};

export default App;
